<template>
    <div
        class="wicket"
        v-if="isShow"
    >
        <div class="wicketBox">
            <div class="wicket_title">
                <div class="head_box">
                    <p class="title_p">
                        <!--  @blur="editSessionName" -->
                        <span id="newSessionName">{{ sessionName || detailDataName }}</span>
                        <span
                            v-if="tabIndex === 1 || sessionType === 'groupChat'"
                            :class="{unfold_p: true, unfold_p_f: isShowTeamMember}"
                            @click="showTeamMember()"
                        >
                            <i
                                class="iconfont iconzhankai4"
                            ></i>
                        </span>
                    </p>
                    <p class="edit_p" v-if="tabIndex === 1 || sessionType === 'groupChat'" @click="editName">
                        <i class="el-icon-edit"></i>
                    </p>
                </div>
                <!-- <button></button> -->
                <ul :class="{active:isShowTeamMember}" class="PersonnelList">
                    <li
                        v-for="memberItem in membersParse"
                        :title="memberItem.alias"
                        :key="memberItem.id"
                        @mouseover="changeActive($event,memberItem.alias)"
                        @mouseout="removeActive($event,memberItem.alias)"
                        @click="removeMember(memberItem)"
                        @contextmenu.prevent="openMenu($event, memberItem)"
                    >
                        <div v-if="memberItem.AvatarUrl">
                            <img :src="memberItem.AvatarUrl" alt="">
                        </div>
                        <div v-else :style="`background: ${memberItem.User_Gender === 0 ? '#fe86a8' : '#4da9ff'}`">
                            {{ memberItem.alias|getLastCharacter }}
                        </div>
                        <!-- <span class="remove">移除</span> -->
                    </li>
                    <li class="AddTo" @click="handleAddTeamMember">
                        <span class="iconfont icontianjia"></span>
                    </li>
                </ul>
            </div>
            <div class="wicket_content">
                <div class="ProjectInfo" v-if="isShowCard">
                    <div class="xx">
                        <p>1</p>
                        <p>青岛中联公司送料申请</p>
                        <p>2018-12-18 18:33</p>
                    </div>
                    <div class="personnel">
                        <span>事项：</span>
                        <ul>
                            <li>送料</li>
                        </ul>
                    </div>
                    <button class="ViewDetails" ng-click="OpenMatterWizard($event,BusinessInstanceInfo)"></button>
                </div>
                <div class="DialogBox" :class="{ active:isShowCard }">
                    <ul ref="message_box" id="chatContent">
                        <li
                            v-for="(msgItem, i) in msglist"
                            :id="`${msgItem.id}`"
                            :key="msgItem.idClient"
                            :class="{'Info_l': msgItem.flow === 'in','Info_r': msgItem.flow === 'out','remind':msgItem.type=='notification'}"
                        >
                            <div
                                class="InfoTime"
                                v-if="msgItem.type=='timeTag'"
                            >
                                <strong>---- {{ msgItem.showText }} ----</strong>
                            </div>
                            <div
                                class="HeadPortrait"
                                v-if="msgItem.type!=='notification'&&msgItem.type!=='timeTag'"
                            >
                                <div v-if="msgItem.AvatarUrl">
                                    <img :src="msgItem.AvatarUrl" alt="">
                                </div>
                                <div v-else :style="`background: ${msgItem.User_Gender === 0 ? '#fe86a8' : '#4da9ff'}`">
                                    {{ msgItem.name }}
                                </div>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='txt'"
                            >
                                <!-- <span>{{ msgItem.fromNick }}</span> -->
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p v-html="msgItem.showText"></p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='notification'"
                            >
                                <p>{{ msgItem.showText }}</p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='image'"
                            >
                                <p>
                                    <img :src="msgItem.file.url + '?imageView&thumbnail=180x0&quality=85'">
                                </p>
                            </div>
                            <div
                                class="Info"
                                v-if="msgItem.type=='file'"
                            >
                                <p>
                                    <a @click="openFileFun(msgItem)" target="_blank" class="clearfix">
                                        <span class="icon_b">
                                            <i class="iconfont fl" :class="msgItem.icon ? msgItem.icon : 'iconppt'"></i>
                                        </span>
                                        <span class="fr">
                                            <span class="fl">{{ msgItem.showText }}</span>
                                            <span class="fl">{{ Math.round(msgItem.size / 1024 * 10) / 10 + 'kb' }}</span>
                                        </span>
                                    </a>
                                </p>
                                <div v-if="msgItem.content" class="content_box">
                                    {{ msgItem.content }}
                                </div>
                            </div>
                            <div
                                class="Info voiceInfo"
                                :class="{ info_right: msgItem.flow === 'out' }"
                                v-if="msgItem.type=='voice'"
                                @click.stop="playVoiceFun(i, msgItem)"
                            >
                                <p>
                                    <i v-if="msgItem.flow === 'in'" class="iconfont iconyuyin-cuxiantiao-copy" style="padding-right: .05rem;"></i>
                                    {{ msgItem.duration || 0 }}′′
                                    <i v-if="msgItem.flow === 'out'" class="iconfont iconyuyin-cuxiantiao" style="padding-left: .05rem;"></i>
                                </p>
                                <!-- <i v-if="msgItem.flow === 'in'" class="iconfont icondot" :class="{ icondot_r: msgItem.flow === 'in'}"></i> -->
                            </div>
                        </li>
                    </ul>
                    <div class="chat-bottom">
                        <div class="tool" @click="senInputFun">
                            <div
                                id="emojiTag"
                                class="m-emojiTag"
                                v-if="isEmojiShown"
                            >
                                <div
                                    class="m-emoji-wrapper m-chat-emoji"
                                    style="width: 420px; height: 220px;"
                                >
                                    <chat-emoji
                                        :type="session.type"
                                        :scene="session.scene"
                                        :to="session.to"
                                        @add-emoji="addEmoji"
                                        @hide-emoji="hideEmoji"
                                    ></chat-emoji>
                                </div>
                            </div>
                            <span class="Expression" @click="showEmoji">
                                <span class="iconfont iconbiaoqing"></span>
                            </span>
                            <span class="Expression" :class="{currVoice: isVoice}" @click="showVoice">
                                <span class="iconfont iconyuyin"></span>
                            </span>
                            <span class="file" @click="fileClickFun">
                                <span class="iconfont iconwenjianjia"></span>
                                <input
                                    multiple="multiple"
                                    type="file"
                                    name="file"
                                    id="uploadFile"
                                    ref="fileToSent"
                                    @change="sendFileMsg"
                                >
                            </span>
                        </div>
                        <textarea v-if="!isVoice" v-model="msgToSent" @input="senInputFun"></textarea>
                        <el-popover
                            v-else
                            placement="top"
                            width="200"
                            trigger="click"
                        >
                            <div slot="reference" class="voice_box">
                                <div class="voice">
                                    <div class="icon_box">
                                        <i class="iconfont icona-24gf-playCircle"></i>
                                    </div>
                                    <div class="icon_txt">
                                        继续录制
                                    </div>
                                </div>
                            </div>
                            <div class="voice_content">
                                <div class="title">
                                    单击开始录音，最长可录制
                                </div>
                                <div class="mic_icon">
                                    <i @click="recordFun" class="iconfont iconyuyin"></i>
                                </div>
                                <div class="time">
                                    {{ timeNum }}′′
                                </div>
                                <div class="btns">
                                    <div class="btn">
                                        <div @click="destroyRecorder" class="btn_box">
                                            <i class="iconfont icona-guanbi1"></i>
                                        </div>
                                        <div class="btn_txt">
                                            取消重录
                                        </div>
                                    </div>
                                    <div class="btn">
                                        <div @click="pauseRecorder" class="btn_box">
                                            <i class="iconfont iconzanting"></i>
                                        </div>
                                        <div class="btn_txt">
                                            暂停录制
                                        </div>
                                    </div>
                                    <div class="btn">
                                        <div @click="stopRecorder" class="btn_box">
                                            <i class="iconfont iconduigouxiao"></i>
                                        </div>
                                        <div class="btn_txt">
                                            发送语音
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-popover>
                        <form
                            action="#"
                            id="uploadForm"
                            class="submit-form"
                        >
                            <input
                                type="button"
                                @click="sendTextMessage()"
                                value="发送"
                                class="button"
                            >
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="请输入名称"
            :visible.sync="editNameDialog"
            :close-on-click-modal="false"
            center
            class="option-dialog"
            :modal="false"
        >
            <div>
                <el-input type="text" v-model="newName"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="editSessionName">确定</el-button>
                <el-button @click="editNameDialog = false">取 消</el-button>
            </span>
        </el-dialog>
        <teamChoseMembers
            :show-dialogue="showTeamChoseDialogue"
            :dialogue-tit="'添加群组成员'"
            @update-show="updateShowDialogue"
            @sure-members="sureMembers"
            :type="'addTeamMember'"
            :members="existMembers"
        ></teamChoseMembers>
        <div v-if="showDropdown" class="menu_box" :style="`position: fixed; left: ${menuLeft}px; top: ${menuTop}px;`">
            <div @click="menuDelFun">
                移出群聊
            </div>
            <div @click="menuSendFun">
                发消息
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import util from '@/common/NIM/utils';
import utils from '@/utils/util';
import config from '@/common/NIM/configs';
import emojiObj from '@/common/NIM/configs/emoji';
import ChatEmoji from './ChatEmoji'
import teamChoseMembers from './../teamChoseMembers';
import Recorder from 'js-audio-recorder';
import lamejs from 'lamejs'
import { matchType } from '@/views/messageCenter/js/matchType.js';
export default {
    components: {
        ChatEmoji,
        teamChoseMembers,
    },
    props: {
        isShow: {
            type: [Boolean],
        },
        session: {
            type: [Object, String],
        },
        chatdata: {
            type: [Object],
        },
        tabIndex: {
            type: [Number, String],
        },
        sessionType: {
            type: [Number, String],
        },
        contactsData: {
            type: [Array],
        },
    },
    data() {
        return {
            msgToSent: '',
            isShowTeamMember: false,
            isEmojiShown: false,
            sessionId:'',
            isShowCard: false,
            editNameDialog: false,
            newName: '',
            showTeamChoseDialogue: false,
            existMembers: [],
            membersParse: [],

            isVoice: false,
            timeNum: 60,
            recorder: null,
            timeInter: null,

            aliyunOssToken: null,
            buCode: 'Flow',
            isUnfold: false,
            showDropdown: false,
            menuTop: 0,
            menuLeft: 0,
            DetailData: {},

            chatOpt: {
                // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
                to: '',
                // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`，默认为单聊。
                chatType: 'singleChat',
                name: '', // 名称
            },

            input: {
                text: '',
                focus: false,
                ext: {},
            },
            msgsData: [],

            im_userInfo: {},
            files: [],
            fileIds: [],
            messageVal: '',

            audios: {},
            playObj: {},
            userData: {},

            messageId: null,
            scrollSign: true,
            sendFlag: true,
            scrollTopNum: 0,

            detailDataName: '',
        };
    },
    updated() {
        if (this.sendFlag && this.timeNum === 60) {
            // 滚动到底部
            this.$nextTick(function () {
                if (this.scrollSign) {
                    setTimeout(() => {
                        const div = document.getElementById('chatContent');
                        div.scrollTop = div.scrollHeight;
                        this.scrollTopNum = div.scrollHeight;
                    }, 100);
                } else {
                    const div = document.getElementById('chatContent');
                    div.scrollTop = div.scrollHeight - this.scrollTopNum;
                    this.scrollTopNum = div.scrollHeight;
                }
            });
        } else {
            this.sendFlag = true;
        }
    },
    computed: {
        msglist() {
            const msgs = this.msgsData;
            // const msgs = [
            //     {
            //         type: 'timeTag',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'notification',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'text',
            //         showText: '11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'image',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'file',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'image',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //     },
            //     {
            //         type: 'file',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //         size: '2.1MB',
            //         content: '先帝创业未半而中道崩殂(cú)，今天下三分，益州疲(pí)弊。',
            //     },
            //     {
            //         type: 'file',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //         size: '2.1MB',
            //         content: '先帝创业未半而中道崩殂(cú)。',
            //     },
            //     {
            //         type: 'file',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconppt',
            //         size: '2.1MB',
            //         content: '先帝创业未半而中道崩殂(cú)，今天下三分，益州疲(pí)弊，此诚危急存亡之秋也。',
            //     },
            //     {
            //         type: 'file',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconexcel',
            //         size: '2.1MB',
            //         content: '先帝创业未半而中道崩殂(cú)，今天下三分，益州疲(pí)弊，此诚危急存亡之秋也。然侍卫之臣不懈(xiè)于内，忠志之士忘身于外者，盖追先帝之殊遇，欲报之于陛下也。诚宜开张圣听，以光先帝遗(yí)德，恢弘志士之气，不宜妄自菲薄，引喻失义，以塞(sè)忠谏之路也。',
            //     },
            //     {
            //         type: 'voice',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'in',
            //         fromNick: '1111111',
            //         icon: 'iconyuyin-cuxiantiao-copy',
            //         size: '2.1MB',
            //         num: '50',
            //     },
            //     {
            //         type: 'voice',
            //         showText: '11111',
            //         text: '22222',
            //         file: {
            //             url: 'xxxx',
            //         },
            //         fileLink: 'ccc',
            //         flow: 'out',
            //         fromNick: '1111111',
            //         icon: 'iconyuyin-cuxiantiao',
            //         size: '2.1MB',
            //         num: '50',
            //     },
            // ];
            const newMsgs = msgs.map((item, i) => {
                const newItem = this.computedItem(item);
                return newItem;
            });
            return newMsgs;
        },
        sessionName() {
            const sessionId = this.sessionId;
            let user = null;
            if (/^p2p-/.test(sessionId)) {
                user = sessionId.replace(/^p2p-/, '');
                if (user === window.$globalHub.$store.state.userUID) {
                    return '我';
                } if (this.isRobot) {
                    return this.robotInfos[user].nick;
                }
                let name = '';
                if(window.$globalHub.$store.state.userInfos[user]){
                    const userInfo = window.$globalHub.$store.state.userInfos[user];
                    name = util.getFriendAlias(userInfo);
                } else {
                    name = this.chatdata.name||this.chatdata.friend_name;
                }
                return name;
            }    
                
            if (/^team-/.test(sessionId)) {
                if (this.teamInfo) {
                    // teamInfo中的人数为初始获取的值，在人员增减后不会及时更新，而teamMembers在人员增减后同步维护的人员信息
                    const members = window.$globalHub.$store.state.teamMembers && window.$globalHub.$store.state.teamMembers[this.teamInfo.teamId];
                    const memberCount = members && members.length;
                    // return this.teamInfo.name + (memberCount ? `(${memberCount})` : '');
                    return this.teamInfo.name;
                }
                return '群';
            }
        },
        scene() {
            return util.parseSession(this.sessionId).scene;
        },
        to() {
            return util.parseSession(this.sessionId).to;
        },
        // 判断是否是机器人
        isRobot() {
            const sessionId = this.sessionId;
            let user = null;
            if (/^p2p-/.test(sessionId)) {
                user = sessionId.replace(/^p2p-/, '');
                if (this.robotInfos[user]) {
                    return true;
                }
            }
            return false;
        },
        myInfo() {
            return window.$globalHub.$store.state.myInfo;
        },
        userInfos() {
            return window.$globalHub.$store.state.userInfos;
        },
        robotInfos() {
            return window.$globalHub.$store.state.robotInfos;
        },
        teamInfo() {
            if (this.scene === 'team') {
                const teamId = this.sessionId.replace('team-', '');
                return window.$globalHub.$store.state.teamlist.find(team => {
                    return team.teamId === teamId;
                });
            }
            // eslint-disable-next-line no-undefined
            return undefined;
        },
        muteInTeam() {
            if (this.scene !== 'team') {return false;}
            const teamMembers = window.$globalHub.$store.state.teamMembers;
            const Members = teamMembers && teamMembers[this.teamInfo.teamId];
            const selfInTeam = Members && Members.find(item => {
                return item.account === window.$globalHub.$store.state.userUID;
            });
            return selfInTeam && selfInTeam.mute || false;
        },
        teamInvalid() {
            if (this.scene === 'team') {
                return !(this.teamInfo && this.teamInfo.validToCurrentUser);
            }
            return false;
        },
        sendInvalidHint() {
            if (this.scene === 'team' && this.teamInvalid) {
                return `您已不在该${this.teamInfo && this.teamInfo.type === 'normal' ? '讨论组' : '群'}，不能发送消息`;
            } if (this.muteInTeam) {
                return '您已被禁言';
            }
            return '无权限发送消息';
        },
        members() {
            var members = window.$globalHub.$store.state.teamMembers[this.sessionId.split('-')[1]];
            var userInfos = window.$globalHub.$store.state.userInfos;
            var needSearchAccounts = [];
            if (members) {
                members = members.map( item => {
                var member = Object.assign({}, item) //重新创建一个对象，用于存储展示数据，避免对vuex数据源的修改
                member.valid = true //被管理员移除后，标记为false
                if (member.account === window.$globalHub.$store.state.userUID) {
                    member.alias = '我'
                    member.avatar = window.$globalHub.$store.state.myInfo.avatar
                    this.isOwner = member.type === 'owner'
                    this.hasManagePermission = member.type !== 'normal'
                } else if (userInfos[member.account] === undefined) {
                    needSearchAccounts.push(member.account)
                    member.avatar = member.avatar || this.avatar
                    member.alias = member.nickInTeam || member.account
                } else {
                    member.avatar = userInfos[member.account].avatar
                    member.alias = member.nickInTeam ||userInfos[member.account].nick
                }
                return member
                })
                // if (needSearchAccounts.length>0 && !this.hasSearched) {
                // this.hasSearched = true
                // while(needSearchAccounts.length>0) {
                //     this.searchUsers(needSearchAccounts.splice(0, 150))
                // }
                // }
                return members
            }
            return []
        }
    },
    watch: {
        session(val) {
            this.isShowTeamMember = false;
            this.scrollSign = true;
            this.sendFlag = true;
            this.messageId = null;
            this.msgsData = [];
            this.sessionId = val;
            // 此时设置当前会话
            // window.$globalHub.$store.dispatch('setCurrSession', this.sessionId);
            // window.$globalHub.$store.dispatch('getHistoryMsgs', {scene:this.scene,to:this.to});
            // 获取群成员
            if (this.scene === 'team') {
                const teamMembers = window.$globalHub.$store.state.teamMembers[this.to];
                // eslint-disable-next-line no-undefined
                if (teamMembers === undefined || teamMembers.length < this.teamInfo.memberNum) {
                    window.$globalHub.$store.dispatch('getTeamMembers', this.to);
                }
            }
            this.getMessage();
            setTimeout(() => {
                this.$refs.message_box.addEventListener('scroll', this.lazyLoading); // 滚动到底部，再加载的处理事件
            }, 500);
        },
    },
    onLoad(params) {
        // this.chatOpt.to = params.to;
        // this.chatOpt.chatType = params.chatType;
        // this.chatOpt.name = params.name;
        // this.systemInfo = this.$getSystemInfoSync();
        // this.init();
	},
    mounted() {
        // 监听接收消息
        this.$WebIM.conn.addEventHandler('eventName', {
            // SDK 与环信服务器连接成功。
            onConnected: function (message) {
                this.getMessage();
            },
            // SDK 与环信服务器断开连接。
            onDisconnected: function (message) {
                this.getMessage();
            },
            // 当前用户收到文本消息。
            onTextMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到图片消息。
            onImageMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到透传消息。
            onCmdMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到语音消息。
            onAudioMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到位置消息。
            onLocationMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到文件消息。
            onFileMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到自定义消息。
            onCustomMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到视频消息。
            onVideoMessage: function (message) {
                this.getMessage();
            },
            // 当前用户订阅的其他用户的在线状态更新。
            onPresence: function (message) {
                this.getMessage();
            },
            // 当前用户收到好友邀请。
            onContactInvited: function (msg) {
                this.getMessage();
            },
            // 联系人被删除。
            onContactDeleted: function (msg) {
                this.getMessage();
            },
            // 新增联系人。
            onContactAdded: function (msg) {
                this.getMessage();
            },
            // 当前用户发送的好友请求被拒绝。
            onContactRefuse: function (msg) {
                this.getMessage();
            },
            // 当前用户发送的好友请求被同意。
            onContactAgreed: function (msg) {
                this.getMessage();
            },
            // 当前用户收到群组邀请。
            onGroupEvent: function (message) {
                this.getMessage();
            },
            // 本机网络连接成功。
            onOnline: function () {
                this.getMessage();
            },
            // 本机网络掉线。
            onOffline: function () {
                this.getMessage();
            },
            // 调用过程中出现错误。
            onError: function (message) {
                this.getMessage();
            },
            // 当前用户收到的消息被消息发送方撤回。
            onRecallMessage: function (message) {
                this.getMessage();
            },
            // 当前用户发送的消息被接收方收到。
            onReceivedMessage: function (message) {
                // this.getMessage();
            },
            // 当前用户收到消息送达回执。
            onDeliveredMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到消息已读回执。
            onReadMessage: function (message) {
                this.getMessage();
            },
            // 当前用户收到会话已读回执。
            onChannelMessage: function (message) {
                this.getMessage();
            },
        });
        // 模拟外部点击
        document.addEventListener('click', e => {
            if (e.target.className !== 'menu_box') {
                this.DetailData = {};
                this.showDropdown = false;
            }
            if (e.target.className !== 'iconfont iconbiaoqing') {
                this.isEmojiShown = false;
            }
        });
        this.im_userInfo = JSON.parse(localStorage.getItem('im_userInfo'));
        this.getOssToken();
        this.init();
    },
    methods: {
        openFileFun(item) {
            this.getFileDetailFun(item);
        },
        playVoiceFun(i, item) {
            this.getFileDetailFun(item, i);
        },
        // 滚动加载
        lazyLoading (e) {
            const scrollTop = Math.ceil(e.target.scrollTop); // 滚动条滚动时，距离顶部的距离
            this.scrollSign = false;
            this.sendFlag = true;
            // 滚动条到顶部
            if (scrollTop === 0) {
                const div = document.getElementById('chatContent');
                this.scrollTopNum = div.scrollHeight;
                this.getMessage();
            }
        },
        getMessage() {
            let type = 'singleChat';
            if (this.tabIndex === 0) {
                type = 'singleChat';
            } else if (this.tabIndex === 1) {
                type = 'groupChat';
            } else {
                if (this.sessionType && this.sessionType === 'groupChat') {
                    type = 'groupChat';
                }
            }
            let options = {
                targetId: this.sessionId.split('-')[1], // 单聊为对端用户 ID，群组聊天为群组 ID。
                chatType: type, // 会话类型：单聊和群组聊天分别为 `singleChat` 和 `groupChat`。
                pageSize: 10, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
                cursor: this.messageId,
                searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
                searchOptions: {
                    // from: '', // 消息发送方的用户 ID。该参数仅用于群组聊天。 
                    // msgTypes: ['txt'], // 要获取的消息类型的数组。若不传值，会获取所有类型的消息。
                    startTime: new Date('2023,1,9').getTime(), // 查询的起始时间戳，单位为毫秒。
                    endTime: new Date().getTime(), // 查询的结束时间戳，单位为毫秒。
                },
            }
            this.$WebIM.conn.getHistoryMessages(options).then((res) => {
                // 成功获取历史消息。
                const arr = [];
                const resData = res.messages && res.messages[0] ? res.messages.reverse() : [];
                resData.forEach((item, i) => {
                    const itemId = item.ext && item.ext.send_user ? item.ext.send_user : '';
                    if (itemId !== this.im_userInfo.user.username) {
                        item.flow = 'in';
                    } else {
                        item.name = '我';
                        item.flow = 'out';
                        item.User_Gender = this.$takeTokenParameters().User_Type * 1
                    }
                    if (i === 0) {
                        arr.push({
                            type: 'timeTag',
                            showText: '',
                            msg: this.setTimeFun(item.time),
                        });
                    } else if (item.time - resData[i-1].time > 1000 * 60 * 2) {
                        arr.push({
                            type: 'timeTag',
                            showText: '',
                            msg: this.setTimeFun(item.time),
                        });
                    }
                    arr.push(item);
                });
                arr.forEach(item => {
                    if (item.type === 'custom') {
                        item.showText = item.ext.name;
                        item.size = item.ext.size;
                        item.content = item.ext.content;
                        item.duration = item.ext.duration;
                        item.icon = item.ext.icon;
                        item.type = item.ext.type;
                    }
                    this.contactsData.forEach(dat => {
                        if (item.ext && item.ext.send_user && item.ext.send_user === dat.id) {
                            item.AvatarUrl = dat.AvatarUrl;
                            item.name = dat.name;
                            item.User_Gender = dat.User_Gender;
                        }
                    });
                });
                this.messageId = arr[1].id;
                this.msgsData = this.messageId ? [...arr, ...this.msgsData] : arr;
                // if (arr && arr[0]) {
                //     this.getFileDetailAllFun(arr);
                // } else {
                //     this.msgsData = [];
                // }
            })
            .catch((e) => {
                // 获取失败。
            });
        },
        setTimeFun(date) {
            // utils.timeFormat(new Date(newItem.time), 'yyyy-MM-dd HH:mm:ss')
            let timeStr = '';
            const date1 = utils.timeFormat(new Date(date), 'yyyy-MM-dd HH:mm:ss');
            const date2 = utils.timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
            const time1 = new Date(date2).getTime() - new Date(`${date2.split(' ')[0]} 00:00:00`).getTime();
            const time2 = new Date(date2).getTime() - new Date(date1).getTime();
            const time = date1.split(' ')[1].split(':');
            if (time1 >= time2) {
                timeStr = `${time[0]}:${time[1]}`;
            } else if (time1 + 1000 * 60 * 60 * 24 > time2) {
                timeStr = `昨天${time[0]}:${time[1]}`;
            } else {
                timeStr = date1;
            }
            return timeStr;
        },
        // 初始化
        init() {
            this.recorder = new Recorder({
            // 采样位数，支持 8 或 16，默认是16
                sampleBits: 16,
                // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值
                sampleRate: 48000,
                // 声道，支持 1 或 2， 默认是1
                numChannels: 1,
                // 是否边录边转换，默认是false
                compiling: false,
            });
        },
        // 打开菜单
        openMenu(e, row) {
            this.DetailData = row;
            this.menuLeft = e.clientX + 10;
            this.menuTop = e.clientY;
            this.showDropdown = true;
        },
        // 移出群聊
        menuDelFun() {
            let option = {
                groupId: this.sessionId.split('-')[1],
                username: this.DetailData.Contacts_AccId,
            };
            this.$WebIM.conn.removeGroupMember(option).then(res => {
                this.showTeamMember(true);
                this.$message.success('移出成功');
            });
        },
        // 发送消息
        menuSendFun() {
            const str = this.tabIndex === 1 ? 'groupChat' : 'contacts';
            console.log(this.DetailData, 'this.DetailData.id');
            this.detailDataName = this.DetailData.alias;
            // this.sessionId = this.DetailData.Contacts_AccId;
            this.addUseFun(this.DetailData.id);
            this.$emit('clickContact', 'p2p-' + this.DetailData.Contacts_AccId, this.DetailData, { refName: str, flag: true });
        },
        addUseFun(id) {
            const data = [id];
            this.$axios.post('/interfaceApi/message/MessageNetease/betch_add_user_friend', data).then(res => {
                if (res) {
                    setTimeout(() => {
                        this.getMessage();
                        if (this.tabIndex !== 2) {
                            this.$emit('nameChangeFun');
                        }
                    }, 300);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        recordFun() {
            this.startRecorder();
        },
        // 开始录音
        startRecorder() {
            if (this.timeNum !== 60) {
                return;
            }
            const that = this;
            this.recorder.start().then(() => {
                that.timeInter = setInterval(() => {
                    if (that.timeNum > 0) {
                        that.timeNum -= 1;
                    } else {
                        that.$message({
                            showClose: true,
                            message: '录制完成',
                            type: 'success',
                        });
                        clearInterval(that.timeInter);
                    }
                }, 1000);
            },
            error => {
                // 出错了
                console.log(`${error.name} : ${error.message}`);
            },
            );
        },
        // 暂停录音
        pauseRecorder() {
            this.sendFlag = true;
            this.scrollSign = true;
            this.recorder.pause();
            clearInterval(this.timeInter);
            this.timeInter = null;
        },
        // 结束录音
        stopRecorder() {
            this.sendFlag = true;
            this.scrollSign = true;
            if (this.timeNum !== 60) {
                this.recorder.stop();
                clearInterval(this.timeInter);
                this.timeInter = null;
                this.sendRecordFun();
            } else {
                this.$message({
                    showClose: true,
                    message: '请先录制',
                    type: 'warning',
                });
            }
        },
        // 销毁录音
        destroyRecorder() {
            this.sendFlag = true;
            this.scrollSign = true;
            this.recorder.destroy().then(() => {
                clearInterval(this.timeInter);
                this.timeNum = 60;
                this.timeInter = null;
                this.init();
            });
        },
        sendRecordFun() {
            let  file = this.convertToMp3(this.recorder.getWAV());
            const name = new Date().getTime() + '.mp3';
            file = new File([file], name);
            this.upGroupFun(file, name);
        },
        convertToMp3(wavDataView) {
            // 获取wav头信息
            const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
            const { channels, sampleRate } = wav;
            const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
            // 获取左右通道数据
            const result = this.recorder.getChannelData();
            const buffer = [];
            
            const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
            const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
            const remaining = leftData.length + (rightData ? rightData.length : 0);
            
            const maxSamples = 1152;
            for (let i = 0; i < remaining; i += maxSamples) {
                const left = leftData.subarray(i, i + maxSamples);
                let right = null;
                let mp3buf = null;
            
                if (channels === 2) {
                    right = rightData.subarray(i, i + maxSamples);
                    mp3buf = mp3enc.encodeBuffer(left, right);
                } else {
                    mp3buf = mp3enc.encodeBuffer(left);
                }
            
                if (mp3buf.length > 0) {
                    buffer.push(mp3buf);
                }
            }
            
            const enc = mp3enc.flush();
            
            if (enc.length > 0) {
                buffer.push(enc);
            }
            
            return new Blob(buffer, { type: 'audio/mp3' });
        },

        senInputFun() {
            this.sendFlag = false;
        },
        sendTextMessage() {
            if (/^\s*$/.test(this.msgToSent)) {
                this.$message({
                    showClose: true,
                    message: '请不要发送空消息',
                    type: 'warning',
                });
                return;
            } if (this.msgToSent.length > 800) {
                this.$message({
                    showClose: true,
                    message: '请不要超过800个字',
                    type: 'warning',
                });
                return;
            }
            this.msgToSent = this.msgToSent.trim();
            // window.$globalHub.$store.dispatch('sendMessage', {
            //     type: 'text',
            //     scene: this.scene,
            //     to: this.to,
            //     text: this.msgToSent,
            // });
            // this.msgToSent = '';
            const option = {
                type: 'txt',
                msg: this.msgToSent,
                msgHtm: this.msgToSent,
                to: this.to.replace('p2p-', ''),
                id: this.to.replace('p2p-', ''),
                // to: 'SU2303000005',
                // id: 'SU2303000005',
                chatType: this.tabIndex === 1 || this.sessionType === 'groupChat' ? 'groupChat' : this.sessionType,
                ext: {
                    send_user: this.im_userInfo.user.username,
                },
                filesize: '',
                isMy: true,
                from: 'su2211000028',
                sid: '231445211709444',
                time: 1703499691260,
            };
            this.send(option, true);
        },
        // 追加消息
        append(data, isMy) {
            data.isMy = isMy;
            // data.from = this.$imoption.Uid.toLowerCase();
            // 开启滚动
            this.scroller.animation = true;
            // 添加
            this.list.push(data);
            // 滚动到底
            this.scrollBottom();
        },

        // todo 发送消息 
        send(data, isAppend) {
            // 先获取连接状态，在发送
            // const msg = this.$WebIM.message.create(data);
            console.log(data, 'mmmmmmmm');
            this.$WebIM.conn.send(data).catch(e => {
                console.error(e);
            });
            this.scrollSign = true;
            let time = this.msglist [0] ? [this.msglist.length - 1].time : new Date().getTime();
            if (new Date().getTime() - time > 1000 * 60 * 2) {
                this.msgsData.push({
                    type: 'timeTag',
                    showText: '',
                    msg: this.setTimeFun(time),
                });
            }
            this.msgsData.push({
                type: 'txt',
                showText: data.msg,
                msg: data.msg,
                file: { url: '' },
                fileLink: '',
                flow: 'out',
                name: '我',
                fromNick: '',
                icon: '',
                User_Gender: this.$takeTokenParameters().User_Type * 1,
            });
            this.msgToSent = '';
            setTimeout(() => {
                // this.getMessage();
                if (this.tabIndex !== 2) {
                    this.$emit('nameChangeFun');
                }
            }, 300);
            // if (isAppend) {
            //     data.from = this.$imoption.Uid.toLowerCase();
            //     // 处理下带表情的 文字,表情符号转图片
            //     const msg = this.$WebIM.handleMsg(data);
            //     this.append(data, true);
            // }
            // data.time = new Date().getTime();
            // msgCache.pushMsg(data);
        },
        // eslint-disable-next-line complexity
        computedItem(rawMsg) {
            const item = Object.assign({}, rawMsg);
            if (item.type === 'timeTag') {
                // 标记发送的时间
                item.showText = item.msg;
            } else if (item.type === 'txt') {
                // 文本消息
                item.showText = util.escape(item.msg);
                if (/\[[^\]]+\]/.test(item.showText)) {
                    const emojiItems = item.showText.match(/\[[^\]]+\]/g);
                    emojiItems.forEach(text => {
                        const emojiCnt = emojiObj.emojiList.emoji;
                        if (emojiCnt[text]) {
                            item.showText = item.showText.replace(text, `<img class="emoji-small" src="${emojiCnt[text].img}">`);
                        }
                    });
                }
            } else if (item.type === 'custom') {
                // const content = item.ext;
                // item.type = 'file';
                
            } else if (item.type === 'image') {
                // 原始图片全屏显示
                item.originLink = item.file.url;
            } else if (item.type === 'video') {
                // ...
            } else if (item.type === 'audio') {
                item.width = (5.3 + Math.round(item.file.dur / 1000) * 0.03).toFixed(2) + 'rem';
                item.audioSrc = item.file.mp3Url;
                item.showText = '<i>' + Math.round(item.file.dur / 1000) + '"</i> 点击播放';
                if (!this.isHistory) {
                    item.unreadAudio = !item.localCustom;
                }
            } else if (item.type === 'file') {
            } else if (item.type === 'notification') {
                if (item.scene === 'team') {
                    item.showText = util.generateTeamSysmMsg(item);
                } else {
                    // 对于系统通知，更新下用户信息的状态
                    item.showText = util.generateChatroomSysMsg(item);
                }
            } else if (item.type === 'tip') {
                // 对于系统通知，更新下用户信息的状态
                item.showText = item.tip;
            } else {
                item.showText = `[${util.mapMsgType(item)}],请到手机或电脑客户端查看`;
            }
            return item;
        },

        // 获取文件下载预览地址
        getFileDetailAllFun(data) {
            const arr = data;
            arr.forEach((item, i) => {
                if (item.type !== 'custom') {
                    if (i === arr.length - 1) {
                        this.msgsData = arr
                    }
                    return;
                }
                this.$axios
                    .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + item.ext.id)
                    .then(res => {
                        item.fileLink = res.download_url;
                        item.preview_url = res.preview_url;
                        item.showText = item.ext.name;
                        item.size = item.ext.size;
                        item.content = item.ext.content;
                        item.duration = item.ext.duration;
                        item.icon = item.ext.icon;
                        item.type = item.ext.type;
                        if (i === arr.length - 1) {
                            this.msgsData = arr;
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },
        // 获取文件下载预览地址
        getFileDetailFun(item, i) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + item.ext.id)
                .then(res => {
                    item.fileLink = res.download_url;
                    item.preview_url = res.preview_url;
                    item.showText = item.ext.name;
                    item.size = item.ext.size;
                    item.content = item.ext.content;
                    item.duration = item.ext.duration;
                    item.icon = item.ext.icon;
                    item.type = item.ext.type;

                    if (i === undefined) {
                        window.open(res.download_url, '_blank');
                    } else {
                        if (this.audios[i] === undefined) {
                            this.audios[i] = new Audio();
                            this.audios[i].src = res.preview_url;
                        }
                        if (this.playObj[i] === undefined) {
                            this.playObj[i] = {
                                flag: false,
                                setTime: null,
                            };
                        }
                        Object.keys(this.audios).forEach(key => {
                            this.audios[key].pause();
                            this.playObj[key].flag = false;
                            clearTimeout(this.playObj[key].setTime);
                        })
                        if (!this.playObj[i].flag) {
                            this.audios[i].play().then(res => {
                                console.log('成功');
                                this.playObj[i].flag = true;
                                this.playObj[i].setTime = setTimeout(() => {
                                    this.playObj[i].flag = false;
                                }, item.duration * 1000);
                            }).catch(error => {
                                console.log('失败');
                            });
                        } else {
                            console.log('暂停');
                            this.audios[i].pause();
                            this.playObj[i].flag = false;
                        }
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        
        showTeamMember(flag) {
            if (!flag) {
                this.isShowTeamMember = !this.isShowTeamMember;
                if (!this.isShowTeamMember) {
                    return;
                }
            }
            // let pageNum = 1,
            //     pageSize = 100;
            // let option = {
            //     pageNum: pageNum,
            //     pageSize: pageSize,
            //     groupId: this.to,
            // };
            // this.$WebIM.conn.listGroupMembers(option).then((res) => {
            //     console.log(res, 'resss');
            //     this.membersParse = [];
            //     res.data.forEach(item => {
            //         this.membersParse.push({
            //             alias: item.name,
            //             id: item.id,
            //         });
            //     });
            // });
            const accIds = [];
            this.members.map(item=>{
                accIds.push(item.account);
            });
            this.$axios.get(`/interfaceApi/message/MessageNetease/get_group_members?group_id=${this.to}`).then(res => {
                    if (res) {
                        const users = [];
                        res.data[0].affiliations.forEach(item => {
                            if (item.member) {
                                users.push(item.member);
                            }
                        });
                        // this.existMembers = users;
                        this.getUserDetail(users);
                        // this.membersParse = this.members.map(item=>{
                        //     item.alias = res[item.account];
                        //     return item;
                        // });
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
        },
        getUserDetail(data) {
            this.$axios.post('/interfaceApi/message/MessageNetease/contacts_by_accid', data).then(res => {
                if (res) {
                    this.membersParse = [];
                    const ids = res.map(item => item.Contacts_AccId);
                    console.log(ids, data)
                    data.forEach(id => {
                        console.log(ids.indexOf(id), 'ids.indexOf(id)');
                        if (ids.indexOf(id) === -1) {
                            this.membersParse.push({
                                id: id,
                                alias: id,
                                User_Gender: 1,
                                Contacts_AccId: id,
                            });
                        } else {
                            res.forEach(item => {
                                if (item.Contacts_AccId === id) {
                                    this.membersParse.push({
                                        id: item.User_Id,
                                        alias: item.User_Name,
                                        ...item,
                                    });
                                }
                            });
                        }
                    });
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        showEmoji () {
            this.senInputFun();
            this.isVoice = false;
            this.isEmojiShown = !this.isEmojiShown;
        },
        showVoice () {
            this.senInputFun();
            this.isVoice = true;
            this.isEmojiShown = false;
        },
        fileClickFun () {
            this.isEmojiShown = false;
        },
        hideEmoji () {
            this.isEmojiShown = false;
        },
        addEmoji (emojiName) {
            this.msgToSent += emojiName;
            this.hideEmoji();
        },
        sendFileMsg () {
            if (this.invalid) {
                this.$message.warning(this.invalidHint)
                return
            }
            let files = this.$refs.fileToSent.files;
            this.files = files;
            this.$toast({
                title: false,
                type: 'eject',
                width: '6.5rem',
                height: '7.2rem',
                t_url: 'messageCenter/pages/page/components/dialogue/fileMessage',
                extr: {
                    files: files,
                    saveFun: this.sendFileFun,
                    chatdata: this.chatdata,
                    clearFun: this.clearFun,
                },
            });
            // let ipt = this.$refs.fileToSent.files[0]

            // if (ipt.value) {
            //     window.$globalHub.$store.dispatch('sendFileMsg', {
            //         scene: this.scene,
            //         to: this.to,
            //         fileInput: ipt
            //     })
            // }
        },
        clearFun() {
            this.$refs.fileToSent.value = '';
            this.files = [];
            this.fileIds = [];
        },
        sendFileFun(value) {
            this.messageVal = value;
            // this.sendFileApi();
            this.files.forEach((file, i) => {
                this.upGroupFun(file, file.name, i);
            });
        },
        sendFileApi(item, type) {
            // 自定义事件。
            let customEvent = 'customEvent';
            // 通过键值对设置自定义消息内容。
            let customExts = {};
            let option = {
                // 消息类型。
                type: 'custom',
                // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
                to: this.to,
                // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
                chatType: this.tabIndex === 1 || this.sessionType === 'groupChat' ? 'groupChat' : this.sessionType,
                customEvent,
                customExts,
                // 消息扩展字段，不能设置为空，即设置为 'ext:null' 这种形式会出错。
                ext: {
                    id: item.id,
                    size: item.file_size,
                    name: item.file_name,
                    content: this.messageVal,
                    duration: Math.ceil(this.recorder.duration),
                    type,
                    icon: matchType(item.file_name).icon,
                    send_user: this.im_userInfo.user.username,
                },
            };
            if (type === 'voice') {
                this.$message.success('发送成功');
                this.destroyRecorder();
            }
            // 创建一条自定义消息。
            let msg = this.$WebIM.message.create(option);
            // 调用 `send` 方法发送该自定义消息。
            this.$WebIM.conn.send(msg).then((res) => {
                // 消息成功发送回调。
                setTimeout(() => {
                    // this.getMessage();
                    let time = this.msglist [0] ? [this.msglist.length - 1].time : new Date().getTime();
                    if (new Date().getTime() - time > 1000 * 60 * 2) {
                        this.msgsData.push({
                            type: 'timeTag',
                            showText: '',
                            msg: this.setTimeFun(time),
                        });
                    }
                    this.msgsData.push({
                        type: type,
                        showText: option.ext.name,
                        size: option.ext.size,
                        content: option.ext.content,
                        duration: option.ext.duration,
                        flow: 'out',
                        name: '我',
                        icon: option.ext.icon,
                        AvatarUrl: '',
                        User_Gender: this.$takeTokenParameters().User_Type * 1,
                        ext: option.ext,
                    });
                    if (this.tabIndex !== 2) {
                        this.$emit('nameChangeFun');
                    }
                }, 300);
                setTimeout(() => {
                    const div = document.getElementById('chatContent');
                    div.scrollTop = div.scrollHeight;
                }, 500);
            }).catch((e) => {
                // 消息发送失败回调。
                console.log(e, '发送失败');
            });
            // this.clearFun();


            // let data = {
            //     FromAccid: 'SU2303000005',
            //     ToAccid: this.to,
            //     ChatType: this.tabIndex === 1 || this.sessionType === 'groupChat' ? 1 : 0,
            //     PostScript: this.messageVal,
            //     FileList: this.fileIds.join(','),
            // };
            // this.$axios.post('/interfaceApi/message/message_im_inbox/create', data).then(res => {
            //     if (res) {
            //         this.fileIds = [];
            //         setTimeout(() => {
            //             this.getMessage();
            //             if (this.tabIndex !== 2) {
            //                 this.$emit('nameChangeFun');
            //             }
            //         }, 300);
            //         console.log(res, 'ress');
            //     }
            // });
        },
        // 修改名称
        editName() {
            let height = '2.1rem';
            let title = '请编辑群组名称';
            let type = 2;
            if (this.scene && this.scene !== 'team') {
                height = '2.3rem';
                title = '请编辑群组名称';
                type = 2;
            } else {
                height = '4.1rem';
                title = '请编辑描述';
                type = 1;
            }
            this.$toast({
                title: false,
                type: 'eject',
                width: '6.5rem',
                height,
                t_url: 'messageCenter/pages/page/components/dialogue/editDes',
                extr: {
                    type,
                    title,
                    value: this.sessionName,
                    saveFun: this.editSessionName,
                },
            });
            // this.editNameDialog = true;
        },
        editSessionName(newName, type) {
            const _this = this;
            const option = {
                groupId: this.chatdata.id,
                groupName: newName,
                // description: "A description of group",
                // ext: "group detail extensions",
            };
            this.$WebIM.conn.modifyGroup(option).then(res => {
                this.chatdata.name = newName;
                this.$emit('nameChangeFun');
            });
        },
        handleAddTeamMember() {
            this.showTeamChoseDialogue = true;
            const arr = [];
            this.membersParse.map(item=>{
                arr.push(item.Contacts_AccId);
            });
            this.existMembers = arr;
        },
        updateShowDialogue() {
            this.showTeamChoseDialogue = false;
        },
        sureMembers(data) {
            if(data.data.length>0){
                const counts = [];
                data.data.map(item=>{
                    counts.push(item.User_Id);
                });
                this.$WebIM.conn.inviteUsersToGroup({ groupId: this.to, users: counts }).then(res => {
                    this.$message.success('添加成功');
                    this.showTeamMember(true);
                    this.showTeamChoseDialogue = false;
                });
            }
        },
        // 鼠标悬浮显示移除
        changeActive($event,alias){
            // if (alias!=='我') {
            //     $event.currentTarget.className="remove";
            // }
        },
        removeActive($event){
            $event.currentTarget.className="";
        },
        // 移除群成员
        removeMember(data) {
            if (data.alias!=='我') {
                const _this = this;
                const options = {
                    teamId: this.to,
                    accounts: [data.account],
                    done:(error, team)=>{
                        _this.isShowTeamMember = !_this.isShowTeamMember;
                    }
                };
                window.$globalHub.$store.dispatch('removeTeamMember', options);
            }
        },

        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + `credential/GetPostPolicy/${this.buCode}`).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                    // localStorage.setItem('aliyunOssToken', JSON.stringify(res));
                }
            });
        },
        // 上传组名
        upGroupFun(file, filename, i) {
            // 判断是否过期，过期重新调用获取通信证方法
            const formData = new FormData();
            // 注意formData里append添加的键的大小写
            formData.append('key', `${this.aliyunOssToken.dir}${this.chatdata.id}/${filename}`); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', this.aliyunOssToken.accessid); // accessKeyId
            formData.append('policy', this.aliyunOssToken.policy); // policy
            formData.append('Signature', this.aliyunOssToken.signature); // 签名
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('file', file);
            // formData.append('FileDescription', this.newFile.FileDescription);
            formData.append('success_action_status', 200); // 成功后返回的操作码
            formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

            // 回调参数
            const obj = {
                bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                business_type_code: this.buCode, // 业务类型编码
                object_name: `${this.aliyunOssToken.dir}${this.chatdata.id}/${filename}`, // 文件对象名
                show_name: filename, // 显示文件名
                size: file.size, // 文件大小
                mimeType: '',
                mime_type: file.type,
                data_id: this.chatdata.id, // 业务数据id
                directory_id: '', // 目录id
                tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                // FileDescription: this.newFile.FileDescription, // 文件描述
            };
            this.uploadFun(formData, obj, i);
        },
        // 上传文件
        uploadFun(formData, val, i) {
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                success: data => { // 成功回调
                    this.callBackFun(val, i);
                },
            });
        },
        // 上传的回调
        callBackFun(obj, i) {
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                if (res) {
                    if (i !== undefined) {
                        this.fileIds.push(res);
                        if (this.files.length - 1 === i) {
                            this.fileIds.forEach((item, j) => {
                                this.sendFileApi(item, 'file');
                                if (j === this.fileIds.length - 1) {
                                    this.clearFun();
                                }
                            });
                        }
                    } else {
                        this.sendFileApi(res, 'voice');
                        console.log('上传语音');
                    }
                    console.log('上传成功');
                }
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.menu_box
    width 1.3rem
    padding .1rem 0
    background #FFFFFF
    box-shadow 0px 0px 4px 0px rgba(0,0,0,0.3)
    z-index 99
    div
        font-size 14px
        font-family SourceHanSansCN, SourceHanSansCN
        font-weight 400
        color #333333
        width 1.1rem
        text-align left
        height .32rem
        line-height .32rem
        margin 0 .1rem
        padding 0 .1rem
        cursor pointer
        &:hover
            width 1.1rem
            border-radius 2px
            color #fff
            background linear-gradient(90deg, #3C9CFF 0%, #337CFF 100%)
.voice_box
    height 1.2rem
    display flex
    justify-content center
    align-items center
    .voice
        text-align center
        cursor pointer
        .icon_box
            color #2CAF56
            i
                font-size .3rem
        .icon_txt
            font-size .16rem
.voice_content
    width 100%
    height 2.7rem
    text-align center
    .title
        color #222222
        font-size .18rem
    .mic_icon
        width 1.05rem
        height 1.05rem
        border 3px solid #2CAF56
        border-radius 50%
        margin .2rem auto
        cursor pointer
        i
            color #2CAF56
            font-size .7rem
    .time
        width 100%
        padding .05rem 0
        text-align center
    .btns
        display flex
        .btn
            color #999
            flex 1
            cursor pointer
            .btn_box
                i
                    font-size .28rem
                .iconzanting
                    color #FF9101
                .iconduigouxiao
                    color #2CAF56
            .btn_txt
                font-size .16rem
.currVoice
    color #2CAF56

</style>
